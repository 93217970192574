@import "react-phone-input-2/lib/style.css";
button {
  text-transform: unset !important;
}

.btn-outlined,
.btn-contained {
  font-size: 16px !important;
  border-radius: 8px !important;
}

.btn-contained:disabled {
  background: #0034bb !important;
  color: #ffffff !important;
  opacity: 0.5 !important;
}

.btn-text {
  font-size: 14px !important;
  min-width: unset !important;
  min-height: unset !important;
  text-transform: unset !important;
  padding: 0 !important;
  line-height: 1.5 !important;
  border-radius: 0 !important;
}
.btn-text:hover {
  background-color: transparent !important;
}

.ecn-input {
  width: 100%;
  border: none;
}
.ecn-input .MuiInputBase-root {
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #212121;
}
.ecn-input .MuiInputBase-root .MuiInputBase-input {
  padding: 10px 16px;
}
.ecn-input .MuiInputBase-root .MuiInputBase-input::placeholder {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.3);
}
.ecn-input .MuiInputBase-root:focus, .ecn-input .MuiInputBase-root:active {
  outline: none;
}
.ecn-input .MuiFormHelperText-root {
  margin-left: 2px;
}

.ecn-underline-input {
  width: 100%;
  padding: 10px 16px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  line-height: 1.5;
}
.ecn-underline-input .MuiInputLabel-root {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
}
.ecn-underline-input .MuiInput-input {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
  font-weight: 500;
}
.ecn-underline-input::placeholder {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.3);
  font-weight: 400;
}
.ecn-underline-input:focus, .ecn-underline-input:active {
  outline: none;
}

.ecn-underline-input-error .MuiInputLabel-root {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6) !important;
  font-weight: 400;
}

.ecn-pagination .MuiPagination-ul {
  flex-wrap: nowrap;
}
.ecn-pagination .MuiPagination-ul li .Mui-selected {
  background: rgba(0, 52, 187, 0.05);
  color: #0034bb;
}
.ecn-pagination .MuiPagination-ul li:first-child {
  flex-basis: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.ecn-pagination .MuiPagination-ul li:first-child .MuiButtonBase-root {
  border: 1px solid #d1d5db;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 8px 14px;
}
.ecn-pagination .MuiPagination-ul li:first-child > button::after {
  margin-left: 10px;
  content: "Previous";
}
.ecn-pagination .MuiPagination-ul li:last-child {
  flex-basis: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.ecn-pagination .MuiPagination-ul li:last-child .MuiButtonBase-root {
  border: 1px solid #d1d5db;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 8px 0px;
  display: unset;
}
.ecn-pagination .MuiPagination-ul li:last-child > button::before {
  margin-right: 10px;
  content: "Next";
}

.ecn-breadcrumb {
  margin-top: 40px !important;
  margin-bottom: 25px !important;
}
.ecn-breadcrumb .MuiBreadcrumbs-ol .MuiBreadcrumbs-li {
  font-size: 14px;
  font-weight: 600;
  color: rgba(33, 33, 33, 0.6);
  cursor: pointer;
}
.ecn-breadcrumb .MuiBreadcrumbs-ol .MuiBreadcrumbs-li:last-child {
  color: #212121;
  cursor: default;
}
.ecn-breadcrumb .MuiBreadcrumbs-ol .MuiBreadcrumbs-separator {
  margin-left: 7px;
  margin-right: 7px;
}

.ecn-chip {
  border-radius: 8px !important;
  padding: 4px 10px !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
}

.ecn-checkbox {
  stroke-width: 0.3px !important;
}

.ecn-select {
  width: 100%;
  border-radius: 8px !important;
  font-weight: 500 !important;
  color: #212121 !important;
}
.ecn-select .MuiSelect-select {
  padding: 10px 16px;
}

.ecn-select-empty {
  color: rgba(0, 0, 0, 0.3) !important;
}
.ecn-select-empty .MuiSelect-select {
  font-size: 14px !important;
}

.MuiMenu-root .MuiPaper-root {
  margin-top: 5px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.header-v2 {
  position: fixed;
  top: 30px;
  left: 50%; /* Align the left edge to the center of the viewport */
  transform: translateX(-50%);
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 0px 1px rgba(71, 71, 71, 0.08), 0px 1px 2px 0px rgba(69, 69, 69, 0.24);
  padding: 11px 20px;
  width: 1320px;
  z-index: 33;
  transition: width 0.3s ease;
}
.header-v2 .mlogo {
  display: none;
}
.header-v2 .menu-list .menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-v2 .menu-list .menu .logo {
  margin-left: 10px;
  height: 29px;
  width: 140px;
  cursor: pointer;
}
.header-v2 .menu-list .menu .logo img {
  max-width: 100%;
  max-height: 100%;
}
.header-v2 .menu-list .menu ul {
  list-style: none;
  display: flex;
  align-items: center;
}
.header-v2 .menu-list .menu ul li {
  font-size: 14px;
  font-weight: 500;
  color: rgb(28, 28, 28);
  cursor: pointer;
  padding: 6px 17px;
}
.header-v2 .menu-list .menu ul li:hover {
  border-radius: 4px;
  background: #f4f4f4;
  padding: 6px 17px;
}
.header-v2 .menu-list .menu ul li:hover .dropdown {
  display: flex;
  align-items: center;
}
.header-v2 .menu-list .menu ul li .dropdown {
  display: none;
  position: absolute;
  z-index: 3;
  padding: 22px 0px;
  margin-left: -30px;
  margin-top: 4px;
}
.header-v2 .menu-list .menu ul li .dropdown .container {
  background-color: #ffffff;
  color: #000;
  background: #fff;
  height: max-content;
  border-radius: 10px;
  border: 1px solid rgba(28, 28, 28, 0.1);
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
  min-width: 150px;
  border-radius: 10px;
}
.header-v2 .menu-list .menu ul li .dropdown .container a {
  display: block;
  text-decoration: none;
  text-align: center;
  color: rgb(28, 28, 28);
  font-size: 14px;
  font-weight: 500;
}
.header-v2 .menu-list .menu ul li .dropdown .container .sub-link {
  padding: 10px 15px;
}
.header-v2 .menu-list .menu ul li .dropdown .container .sub-link .sub-des {
  display: flex;
  align-items: center;
  gap: 10px;
}
.header-v2 .menu-list .menu ul li .dropdown .container .sub-link .sub-des p {
  font-weight: 400;
  font-size: 13px;
  color: #212121;
}
.header-v2 .menu-list .menu ul li .dropdown .container .sub-link:hover {
  background: #f4f4f4;
  border-radius: 8px;
}
.header-v2 .menu-list .menu .design-buttons {
  display: flex;
  align-items: center;
  gap: 20px;
}
.header-v2 .menu-list .menu .design-buttons .outlined {
  border: none;
  outline: none;
  background: transparent;
  font-size: 14px;
  font-weight: 500;
  color: rgb(28, 28, 28);
  cursor: pointer;
}
.header-v2 .menu-list .menu .design-buttons .contained {
  border: none;
  outline: none;
  border-radius: 99px;
  background: #111;
  box-shadow: 0px 1px 2px 0px rgba(69, 69, 69, 0.24), 0px 0px 0px 1px rgba(71, 71, 71, 0.08);
  color: rgb(250, 250, 250);
  padding: 25px;
  padding-bottom: 13px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  min-width: 130px;
}
.header-v2 .menu-list .menu .design-buttons .contained span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.6s;
  transform-origin: 50% 100%;
}
.header-v2 .menu-list .menu .design-buttons .contained span:last-child {
  top: 100%;
  transform: translateY(100%);
}
.header-v2 .menu-list .menu .design-buttons .contained:hover span, .header-v2 .menu-list .menu .design-buttons .contained:focus span {
  transform: translateY(-100%);
}
.header-v2 .mob-menu {
  display: none;
}

.reduced-header {
  position: fixed;
  width: 65vw;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 999px;
}

@media only screen and (max-width: 767px) {
  .header-v2 {
    width: 95vw;
  }
  .header-v2 .menu-list .menu .logo {
    margin-left: 0px;
    height: auto;
    width: 90px;
  }
  .header-v2 .menu-list .menu .logo img {
    display: block;
    margin: auto;
  }
  .reduced-header {
    width: 90vw;
  }
}
.snackbar-cross {
  color: rgba(33, 33, 33, 0.8) !important;
  margin-right: -10px !important;
  margin-top: -49px;
  width: 19px;
  height: 19px;
  cursor: pointer;
}
.snackbar-cross:hover {
  background-color: rgba(33, 33, 33, 0.19);
  border-radius: 50px;
}

.loader-container {
  display: flex;
  width: 100%;
  height: 400px;
  justify-content: center;
  align-items: center;
}

.company-job-list {
  max-width: 1320px;
  margin-inline: auto;
}
.company-job-list .company-details {
  margin-top: 120px;
  padding: 29px 44px;
  border-radius: 12px;
  background: linear-gradient(90deg, #f9fafa 0%, #f5f2f5 49%, #fafaf6 100%);
  border: 1px solid rgba(33, 33, 33, 0.1);
  border-radius: 12px;
}
.company-job-list .company-details .container {
  display: flex;
  align-items: center;
  gap: 16px;
}
.company-job-list .company-details .container .company-logo {
  height: 45px;
  width: 45px;
  border-radius: 6px;
}
.company-job-list .company-details .container h2 {
  font-size: 30px;
  font-weight: 500;
}
.company-job-list .jobs-wrapper {
  margin-top: 20px;
}
.company-job-list .jobs-wrapper .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.company-job-list .jobs-wrapper .head h4 {
  font-size: 20px;
  color: #212121;
  width: max-content;
}
.company-job-list .jobs-wrapper .head .job-search {
  max-width: 340px;
}
.company-job-list .jobs-wrapper .job-list {
  margin-top: 25px;
}
.company-job-list .jobs-wrapper .job-list .job-list-fallback {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 50px;
}
.company-job-list .jobs-wrapper .job-list .job-list-fallback .fallback-message {
  font-size: 24px;
  font-weight: 600;
}
.company-job-list .jobs-wrapper .job-list .job {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-radius: 12px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  background: #ffffff;
  margin-bottom: 18px;
  padding: 22px 24px;
  transition: all 0.3s ease;
}
.company-job-list .jobs-wrapper .job-list .job .data .title {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
}
.company-job-list .jobs-wrapper .job-list .job .data .details {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 12px;
}
.company-job-list .jobs-wrapper .job-list .job .data .details p {
  font-size: 14px;
  color: #212121;
}
.company-job-list .jobs-wrapper .job-list .job .data .details .dot {
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background: #d9d9d9;
}
.company-job-list .jobs-wrapper .job-list .job .data .posted {
  margin-top: 12px;
  font-size: 14px;
  color: rgba(33, 33, 33, 0.7);
}
.company-job-list .jobs-wrapper .job-list .job .apply-btn {
  box-shadow: none;
  padding: 9px 70px;
  min-width: 211px;
}
.company-job-list .jobs-wrapper .job-list .job .apply-btn.closed {
  background-color: #9ca3af !important;
}
.company-job-list .jobs-wrapper .job-list .job:hover {
  cursor: pointer;
  box-shadow: 0px 4px 20px 0px rgba(33, 33, 33, 0.15);
}
.company-job-list .pagination-wrapper {
  margin-top: 39px;
  margin-bottom: 30px;
}
.company-job-list .pagination-wrapper .job-pagination .MuiPagination-ul {
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .company-job-list {
    margin-inline: 16px;
  }
  .company-job-list .company-details {
    margin-top: 100px;
    padding: 20px 16px;
  }
  .company-job-list .company-details .container {
    gap: 6px;
  }
  .company-job-list .company-details .container .company-logo {
    height: 25px;
    width: 25px;
    border-radius: 4px;
  }
  .company-job-list .company-details .container h2 {
    font-size: 14px;
    font-weight: 600;
    color: #212121;
  }
  .company-job-list .jobs-wrapper {
    margin-top: 20px;
  }
  .company-job-list .jobs-wrapper .head h4 {
    font-size: 14px;
  }
  .company-job-list .jobs-wrapper .head .job-search {
    max-width: 180px;
  }
  .company-job-list .jobs-wrapper .job-list {
    margin-top: 25px;
  }
  .company-job-list .jobs-wrapper .job-list .job {
    margin-bottom: 16px;
    padding: 22px 18px;
  }
  .company-job-list .jobs-wrapper .job-list .job .data .details {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    margin-top: 4px;
  }
  .company-job-list .jobs-wrapper .job-list .job .data .details p {
    font-size: 14px;
    color: #212121;
  }
  .company-job-list .jobs-wrapper .job-list .job .data .details .dot {
    display: none;
  }
  .company-job-list .jobs-wrapper .job-list .job .data .posted {
    margin-top: 12px;
    font-size: 14px;
    color: rgba(33, 33, 33, 0.7);
  }
  .company-job-list .jobs-wrapper .job-list .job .apply-btn {
    box-shadow: none;
    padding: 9px 20px;
    font-size: 14px !important;
    min-width: auto;
  }
  .company-job-list .jobs-wrapper .job-list .job .apply-btn .apply-icon {
    display: none;
  }
}
.job-detail {
  max-width: 1320px;
  margin-inline: auto;
}
.job-detail .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 49px;
  margin-bottom: 28px;
}
.job-detail .head .icon {
  display: flex;
  align-items: center;
  gap: 16px;
}
.job-detail .head .icon .divider-vert {
  height: 32px;
  width: 1px;
  background-color: rgba(33, 33, 33, 0.2);
}
.job-detail .head .icon img {
  max-width: 101px;
}
.job-detail .job-card {
  padding: 19px 35px;
  margin-bottom: 50px;
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid rgba(33, 33, 33, 0.2);
}
.job-detail .job-card .title {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.job-detail .job-card .title .title-data {
  display: flex;
  gap: 16px;
}
.job-detail .job-card .title .title-data .firm-logo {
  width: 65px;
  height: 65px;
  border-radius: 4px;
}
.job-detail .job-card .title .title-data .data .company {
  font-size: 14px;
  color: #0034bb;
}
.job-detail .job-card .title .title-data .data .job-title {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-top: 2px;
}
.job-detail .job-card .title .title-data .data .row {
  display: flex;
  gap: 25px;
  margin-top: 6px;
}
.job-detail .job-card .title .title-data .data .row p {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #212121;
}
.job-detail .job-card .title .btn-group .apply-btn {
  border-radius: 7px;
  box-shadow: none;
  padding: 8px 50px;
  margin-right: 25px;
}
.job-detail .job-card .title .btn-group .apply-btn.closed {
  background-color: #9ca3af !important;
}
.job-detail .job-card .title .btn-group .share-btn {
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  font-size: 16px;
  padding: 11px;
}
.job-detail .job-card .meta-data {
  display: flex;
  align-items: flex-start;
  gap: 35px;
  margin-top: 20px;
}
.job-detail .job-card .meta-data .block .heading {
  font-size: 14px;
  color: rgba(33, 33, 33, 0.6);
}
.job-detail .job-card .meta-data .block .info {
  margin-top: 2px;
  font-size: 14px;
  font-weight: 600;
  color: #212121;
}
.job-detail .job-card .skills {
  margin-top: 17px;
}
.job-detail .job-card .skills .skill-head {
  font-size: 14px;
  color: rgba(33, 33, 33, 0.6);
}
.job-detail .job-card .skills .container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 18px;
}
.job-detail .job-card .skills .container .skill-chip {
  border-radius: 27px;
  background: rgba(33, 33, 33, 0.05);
  color: #0034bb;
  font-size: 10px;
  font-weight: 500;
  padding: 5px 10px;
  height: min-content;
}
.job-detail .job-card .skills .container .skill-chip .MuiChip-label {
  padding-inline: 0;
}
.job-detail .job-card .description {
  margin-top: 23px;
}
.job-detail .job-card .description .field .desc-heading {
  font-size: 14px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 14px;
}
.job-detail .job-card .description .field .markdown-data {
  font-size: 14px;
  color: #212121;
  margin-bottom: 18px;
}
.job-detail .job-card .description .field .markdown-data ul,
.job-detail .job-card .description .field .markdown-data ol {
  margin-left: 30px;
}
.job-detail .job-card .description .field .markdown-data ul li ul,
.job-detail .job-card .description .field .markdown-data ul li ol,
.job-detail .job-card .description .field .markdown-data ol li ul,
.job-detail .job-card .description .field .markdown-data ol li ol {
  margin-left: 30px;
}
.job-detail .mob-apply {
  display: none;
}

.share-job-dialog {
  font-family: "Poppins", sans-serif;
}
.share-job-dialog div[role=dialog] {
  border-radius: 8px;
}
.share-job-dialog .dialog-title {
  padding: 20px 0px 11px 0px;
  margin: 0px 20px;
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
  text-align: left;
}
.share-job-dialog .dialog-title .dialog-description {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
}
.share-job-dialog .dialog-content .job-link-container {
  margin-bottom: 8px;
}
.share-job-dialog .dialog-content .job-link-container .link-label {
  margin-bottom: 8px;
}
.share-job-dialog .dialog-content .job-link-container .link-input {
  width: 100%;
}
.share-job-dialog .dialog-content .job-link-container .link-input input {
  padding: 10px 16px;
  border-radius: 12px;
}
.share-job-dialog .dialog-content .job-link-container .link-input fieldset {
  border: none;
}
.share-job-dialog .dialog-content .job-link-container .link-input .MuiInputBase-root {
  outline: 1.5px solid rgba(33, 33, 33, 0.2);
}
.share-job-dialog .dialog-content .job-link-container .link-input.copied-input .MuiInputBase-root {
  outline: 1.5px solid #027a48;
}
.share-job-dialog .dialog-content .job-link-container .copied-text {
  margin-top: 4px;
  color: #027a48;
  font-size: 14px;
  overflow-y: clip;
  animation-name: copied-text-notification;
  animation-duration: 5s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}
@keyframes copied-text-notification {
  0% {
    height: 0px;
  }
  5% {
    height: 18px;
  }
  95% {
    height: 18px;
  }
  100% {
    height: 0px;
  }
}
.share-job-dialog .dialog-content .or-label {
  margin-bottom: 8px;
}
.share-job-dialog .dialog-content .social-icon-container {
  display: flex;
  justify-content: left;
  gap: 10px;
  align-items: center;
  margin-bottom: 4px;
}
.share-job-dialog .dialog-content .social-icon-container .social-icon-button {
  border: 1px solid rgba(33, 33, 33, 0.2);
}
.share-job-dialog .dialog-content .social-icon-container .social-icon-button:hover {
  border: 1px solid rgba(33, 33, 33, 0.6);
}

@media only screen and (max-width: 767px) {
  .job-detail {
    margin-inline: 16px;
  }
  .job-detail .job-card {
    padding: 20px 14px;
    margin-bottom: 75px;
  }
  .job-detail .job-card .title .title-data {
    gap: 10px;
  }
  .job-detail .job-card .title .title-data .firm-logo {
    width: 35px;
    height: 35px;
  }
  .job-detail .job-card .title .title-data .data .row {
    gap: 12px;
    margin-top: 2px;
  }
  .job-detail .job-card .title .title-data .data .row p {
    gap: 2px;
  }
  .job-detail .job-card .title .btn-group .apply-btn {
    display: none;
  }
  .job-detail .job-card .meta-data {
    flex-direction: column;
    gap: 16px;
  }
  .job-detail .job-card .skills {
    margin-top: 16px;
  }
  .job-detail .job-card .skills .container {
    gap: 7px;
  }
  .job-detail .job-card .description {
    margin-top: 20px;
  }
  .job-detail .job-card .description .field .desc-heading {
    margin-bottom: 12px;
  }
  .job-detail .job-card .description .field .markdown-data {
    font-size: 14px;
    color: #212121;
    margin-bottom: 18px;
  }
  .job-detail .job-card .description .field .markdown-data ul,
  .job-detail .job-card .description .field .markdown-data ol {
    margin-left: 30px;
  }
  .job-detail .job-card .description .field .markdown-data ul li ul,
  .job-detail .job-card .description .field .markdown-data ul li ol,
  .job-detail .job-card .description .field .markdown-data ol li ul,
  .job-detail .job-card .description .field .markdown-data ol li ol {
    margin-left: 30px;
  }
  .job-detail .mob-apply {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #ffffff;
    padding: 13px 16px 17px 16px;
    box-shadow: 1px 2px 6px rgba(33, 33, 33, 0.2);
  }
  .job-detail .mob-apply .apply-btn {
    width: 100%;
    border-radius: 7px;
    box-shadow: none;
    padding: 8px 50px;
  }
}
.job-apply {
  max-width: 1320px;
  margin-inline: auto;
}
.job-apply .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 49px;
  margin-bottom: 28px;
}
.job-apply .head .icon {
  display: flex;
  align-items: center;
  gap: 16px;
}
.job-apply .head .icon .divider-vert {
  height: 32px;
  width: 1px;
  background-color: rgba(33, 33, 33, 0.2);
}
.job-apply .head .icon img {
  max-width: 101px;
}
.job-apply .job-card {
  padding: 19px 35px;
  margin-bottom: 25px;
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid rgba(33, 33, 33, 0.2);
}
.job-apply .job-card .title {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.job-apply .job-card .title .title-data {
  display: flex;
  gap: 16px;
}
.job-apply .job-card .title .title-data .firm-logo {
  width: 65px;
  height: 65px;
  border-radius: 4px;
}
.job-apply .job-card .title .title-data .data .company {
  font-size: 14px;
  color: #0034bb;
}
.job-apply .job-card .title .title-data .data .job-title {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin-top: 2px;
}
.job-apply .job-card .title .title-data .data .row {
  display: flex;
  gap: 25px;
  margin-top: 6px;
}
.job-apply .job-card .title .title-data .data .row p {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #212121;
}
.job-apply .job-card .title .btn-group .apply-btn {
  border-radius: 7px;
  box-shadow: none;
  padding: 8px 50px;
}
.job-apply .job-card .meta-data {
  display: flex;
  align-items: flex-start;
  gap: 35px;
  margin-top: 20px;
  margin-bottom: 25px;
}
.job-apply .job-card .meta-data .block .heading {
  font-size: 14px;
  color: rgba(33, 33, 33, 0.6);
}
.job-apply .job-card .meta-data .block .info {
  margin-top: 2px;
  font-size: 14px;
  font-weight: 600;
  color: #212121;
}
.job-apply .job-card .apply-container {
  margin-top: 30px;
}
.job-apply .job-card .apply-container .apply-step_count {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
}
.job-apply .job-card .apply-container .apply-step_count .step-heading {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
}
.job-apply .job-card .apply-container .apply-step_count .progress .step {
  font-size: 14px;
  color: rgba(33, 33, 33, 0.6);
}
.job-apply .job-card .apply-container .apply-step_count .progress .step span {
  font-weight: 600;
  color: #212121;
}
.job-apply .job-card .apply-container .apply-step_count .progress .progress-wrapper {
  padding: 3px;
  background: rgba(0, 52, 187, 0.05);
  border-radius: 9px;
}
.job-apply .job-card .apply-container .apply-step_count .progress .progress-wrapper .job-progress {
  min-width: 125px;
  height: 10px;
  border-radius: 7px;
  background: transparent;
}
.job-apply .job-card .apply-container .apply-step_count .progress .progress-wrapper .job-progress .MuiLinearProgress-bar {
  border-radius: 7px;
}
.job-apply .job-card .apply-container .apply-steps .basic-step,
.job-apply .job-card .apply-container .apply-steps .education-step,
.job-apply .job-card .apply-container .apply-steps .work-step {
  margin-top: 20px;
}
.job-apply .job-card .apply-container .apply-steps .basic-step .no-work-label,
.job-apply .job-card .apply-container .apply-steps .education-step .no-work-label,
.job-apply .job-card .apply-container .apply-steps .work-step .no-work-label {
  margin-bottom: 10px;
}
.job-apply .job-card .apply-container .apply-steps .basic-step .no-work-label .MuiFormControlLabel-label,
.job-apply .job-card .apply-container .apply-steps .education-step .no-work-label .MuiFormControlLabel-label,
.job-apply .job-card .apply-container .apply-steps .work-step .no-work-label .MuiFormControlLabel-label {
  font-size: 14px;
  font-weight: 500;
}
.job-apply .job-card .apply-container .apply-steps .basic-step .container .row,
.job-apply .job-card .apply-container .apply-steps .basic-step .education-group .row,
.job-apply .job-card .apply-container .apply-steps .basic-step .work-group .row,
.job-apply .job-card .apply-container .apply-steps .education-step .container .row,
.job-apply .job-card .apply-container .apply-steps .education-step .education-group .row,
.job-apply .job-card .apply-container .apply-steps .education-step .work-group .row,
.job-apply .job-card .apply-container .apply-steps .work-step .container .row,
.job-apply .job-card .apply-container .apply-steps .work-step .education-group .row,
.job-apply .job-card .apply-container .apply-steps .work-step .work-group .row {
  display: flex;
  gap: 25px;
  margin-bottom: 25px;
}
.job-apply .job-card .apply-container .apply-steps .basic-step .container .row > *,
.job-apply .job-card .apply-container .apply-steps .basic-step .education-group .row > *,
.job-apply .job-card .apply-container .apply-steps .basic-step .work-group .row > *,
.job-apply .job-card .apply-container .apply-steps .education-step .container .row > *,
.job-apply .job-card .apply-container .apply-steps .education-step .education-group .row > *,
.job-apply .job-card .apply-container .apply-steps .education-step .work-group .row > *,
.job-apply .job-card .apply-container .apply-steps .work-step .container .row > *,
.job-apply .job-card .apply-container .apply-steps .work-step .education-group .row > *,
.job-apply .job-card .apply-container .apply-steps .work-step .work-group .row > * {
  flex: 1;
}
.job-apply .job-card .apply-container .apply-steps .basic-step .container .field label,
.job-apply .job-card .apply-container .apply-steps .basic-step .education-group .field label,
.job-apply .job-card .apply-container .apply-steps .basic-step .work-group .field label,
.job-apply .job-card .apply-container .apply-steps .education-step .container .field label,
.job-apply .job-card .apply-container .apply-steps .education-step .education-group .field label,
.job-apply .job-card .apply-container .apply-steps .education-step .work-group .field label,
.job-apply .job-card .apply-container .apply-steps .work-step .container .field label,
.job-apply .job-card .apply-container .apply-steps .work-step .education-group .field label,
.job-apply .job-card .apply-container .apply-steps .work-step .work-group .field label {
  font-size: 14px;
  color: #212121;
  line-height: 171%;
}
.job-apply .job-card .apply-container .apply-steps .basic-step .container .field .work-label .MuiFormControlLabel-label,
.job-apply .job-card .apply-container .apply-steps .basic-step .education-group .field .work-label .MuiFormControlLabel-label,
.job-apply .job-card .apply-container .apply-steps .basic-step .work-group .field .work-label .MuiFormControlLabel-label,
.job-apply .job-card .apply-container .apply-steps .education-step .container .field .work-label .MuiFormControlLabel-label,
.job-apply .job-card .apply-container .apply-steps .education-step .education-group .field .work-label .MuiFormControlLabel-label,
.job-apply .job-card .apply-container .apply-steps .education-step .work-group .field .work-label .MuiFormControlLabel-label,
.job-apply .job-card .apply-container .apply-steps .work-step .container .field .work-label .MuiFormControlLabel-label,
.job-apply .job-card .apply-container .apply-steps .work-step .education-group .field .work-label .MuiFormControlLabel-label,
.job-apply .job-card .apply-container .apply-steps .work-step .work-group .field .work-label .MuiFormControlLabel-label {
  font-size: 14px;
  font-weight: 500;
}
.job-apply .job-card .apply-container .apply-steps .basic-step .container .resume-field .resume-label,
.job-apply .job-card .apply-container .apply-steps .basic-step .education-group .resume-field .resume-label,
.job-apply .job-card .apply-container .apply-steps .basic-step .work-group .resume-field .resume-label,
.job-apply .job-card .apply-container .apply-steps .education-step .container .resume-field .resume-label,
.job-apply .job-card .apply-container .apply-steps .education-step .education-group .resume-field .resume-label,
.job-apply .job-card .apply-container .apply-steps .education-step .work-group .resume-field .resume-label,
.job-apply .job-card .apply-container .apply-steps .work-step .container .resume-field .resume-label,
.job-apply .job-card .apply-container .apply-steps .work-step .education-group .resume-field .resume-label,
.job-apply .job-card .apply-container .apply-steps .work-step .work-group .resume-field .resume-label {
  display: block;
}
.job-apply .job-card .apply-container .apply-steps .basic-step .container .resume-field .upload-btn,
.job-apply .job-card .apply-container .apply-steps .basic-step .education-group .resume-field .upload-btn,
.job-apply .job-card .apply-container .apply-steps .basic-step .work-group .resume-field .upload-btn,
.job-apply .job-card .apply-container .apply-steps .education-step .container .resume-field .upload-btn,
.job-apply .job-card .apply-container .apply-steps .education-step .education-group .resume-field .upload-btn,
.job-apply .job-card .apply-container .apply-steps .education-step .work-group .resume-field .upload-btn,
.job-apply .job-card .apply-container .apply-steps .work-step .container .resume-field .upload-btn,
.job-apply .job-card .apply-container .apply-steps .work-step .education-group .resume-field .upload-btn,
.job-apply .job-card .apply-container .apply-steps .work-step .work-group .resume-field .upload-btn {
  width: max-content;
  border-radius: 9px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 8px 50px;
  text-transform: unset;
  color: #0034bb;
}
.job-apply .job-card .apply-container .apply-steps .basic-step .container .resume-field .uploaded,
.job-apply .job-card .apply-container .apply-steps .basic-step .education-group .resume-field .uploaded,
.job-apply .job-card .apply-container .apply-steps .basic-step .work-group .resume-field .uploaded,
.job-apply .job-card .apply-container .apply-steps .education-step .container .resume-field .uploaded,
.job-apply .job-card .apply-container .apply-steps .education-step .education-group .resume-field .uploaded,
.job-apply .job-card .apply-container .apply-steps .education-step .work-group .resume-field .uploaded,
.job-apply .job-card .apply-container .apply-steps .work-step .container .resume-field .uploaded,
.job-apply .job-card .apply-container .apply-steps .work-step .education-group .resume-field .uploaded,
.job-apply .job-card .apply-container .apply-steps .work-step .work-group .resume-field .uploaded {
  margin-top: 18px;
}
.job-apply .job-card .apply-container .apply-steps .basic-step .container .resume-field .uploaded .file-name-discard,
.job-apply .job-card .apply-container .apply-steps .basic-step .education-group .resume-field .uploaded .file-name-discard,
.job-apply .job-card .apply-container .apply-steps .basic-step .work-group .resume-field .uploaded .file-name-discard,
.job-apply .job-card .apply-container .apply-steps .education-step .container .resume-field .uploaded .file-name-discard,
.job-apply .job-card .apply-container .apply-steps .education-step .education-group .resume-field .uploaded .file-name-discard,
.job-apply .job-card .apply-container .apply-steps .education-step .work-group .resume-field .uploaded .file-name-discard,
.job-apply .job-card .apply-container .apply-steps .work-step .container .resume-field .uploaded .file-name-discard,
.job-apply .job-card .apply-container .apply-steps .work-step .education-group .resume-field .uploaded .file-name-discard,
.job-apply .job-card .apply-container .apply-steps .work-step .work-group .resume-field .uploaded .file-name-discard {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 8px;
}
.job-apply .job-card .apply-container .apply-steps .basic-step .container .resume-field .uploaded .file-name-discard .file-name,
.job-apply .job-card .apply-container .apply-steps .basic-step .education-group .resume-field .uploaded .file-name-discard .file-name,
.job-apply .job-card .apply-container .apply-steps .basic-step .work-group .resume-field .uploaded .file-name-discard .file-name,
.job-apply .job-card .apply-container .apply-steps .education-step .container .resume-field .uploaded .file-name-discard .file-name,
.job-apply .job-card .apply-container .apply-steps .education-step .education-group .resume-field .uploaded .file-name-discard .file-name,
.job-apply .job-card .apply-container .apply-steps .education-step .work-group .resume-field .uploaded .file-name-discard .file-name,
.job-apply .job-card .apply-container .apply-steps .work-step .container .resume-field .uploaded .file-name-discard .file-name,
.job-apply .job-card .apply-container .apply-steps .work-step .education-group .resume-field .uploaded .file-name-discard .file-name,
.job-apply .job-card .apply-container .apply-steps .work-step .work-group .resume-field .uploaded .file-name-discard .file-name {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.job-apply .job-card .apply-container .apply-steps .basic-step .container .resume-field .uploaded .file-name-discard .delete-file-btn,
.job-apply .job-card .apply-container .apply-steps .basic-step .education-group .resume-field .uploaded .file-name-discard .delete-file-btn,
.job-apply .job-card .apply-container .apply-steps .basic-step .work-group .resume-field .uploaded .file-name-discard .delete-file-btn,
.job-apply .job-card .apply-container .apply-steps .education-step .container .resume-field .uploaded .file-name-discard .delete-file-btn,
.job-apply .job-card .apply-container .apply-steps .education-step .education-group .resume-field .uploaded .file-name-discard .delete-file-btn,
.job-apply .job-card .apply-container .apply-steps .education-step .work-group .resume-field .uploaded .file-name-discard .delete-file-btn,
.job-apply .job-card .apply-container .apply-steps .work-step .container .resume-field .uploaded .file-name-discard .delete-file-btn,
.job-apply .job-card .apply-container .apply-steps .work-step .education-group .resume-field .uploaded .file-name-discard .delete-file-btn,
.job-apply .job-card .apply-container .apply-steps .work-step .work-group .resume-field .uploaded .file-name-discard .delete-file-btn {
  cursor: pointer;
  color: #212121;
}
.job-apply .job-card .apply-container .apply-steps .basic-step .container .resume-field .uploaded .uploaded-buttons,
.job-apply .job-card .apply-container .apply-steps .basic-step .education-group .resume-field .uploaded .uploaded-buttons,
.job-apply .job-card .apply-container .apply-steps .basic-step .work-group .resume-field .uploaded .uploaded-buttons,
.job-apply .job-card .apply-container .apply-steps .education-step .container .resume-field .uploaded .uploaded-buttons,
.job-apply .job-card .apply-container .apply-steps .education-step .education-group .resume-field .uploaded .uploaded-buttons,
.job-apply .job-card .apply-container .apply-steps .education-step .work-group .resume-field .uploaded .uploaded-buttons,
.job-apply .job-card .apply-container .apply-steps .work-step .container .resume-field .uploaded .uploaded-buttons,
.job-apply .job-card .apply-container .apply-steps .work-step .education-group .resume-field .uploaded .uploaded-buttons,
.job-apply .job-card .apply-container .apply-steps .work-step .work-group .resume-field .uploaded .uploaded-buttons {
  display: flex;
  gap: 22px;
}
.job-apply .job-card .apply-container .apply-steps .basic-step .container .resume-field .uploaded .uploaded-buttons .upload-btn,
.job-apply .job-card .apply-container .apply-steps .basic-step .education-group .resume-field .uploaded .uploaded-buttons .upload-btn,
.job-apply .job-card .apply-container .apply-steps .basic-step .work-group .resume-field .uploaded .uploaded-buttons .upload-btn,
.job-apply .job-card .apply-container .apply-steps .education-step .container .resume-field .uploaded .uploaded-buttons .upload-btn,
.job-apply .job-card .apply-container .apply-steps .education-step .education-group .resume-field .uploaded .uploaded-buttons .upload-btn,
.job-apply .job-card .apply-container .apply-steps .education-step .work-group .resume-field .uploaded .uploaded-buttons .upload-btn,
.job-apply .job-card .apply-container .apply-steps .work-step .container .resume-field .uploaded .uploaded-buttons .upload-btn,
.job-apply .job-card .apply-container .apply-steps .work-step .education-group .resume-field .uploaded .uploaded-buttons .upload-btn,
.job-apply .job-card .apply-container .apply-steps .work-step .work-group .resume-field .uploaded .uploaded-buttons .upload-btn {
  color: #212121;
  min-width: 119px;
  padding: 8px 30px;
}
.job-apply .job-card .apply-container .apply-steps .basic-step .container .resume-field .uploaded .uploaded-buttons .view-btn,
.job-apply .job-card .apply-container .apply-steps .basic-step .education-group .resume-field .uploaded .uploaded-buttons .view-btn,
.job-apply .job-card .apply-container .apply-steps .basic-step .work-group .resume-field .uploaded .uploaded-buttons .view-btn,
.job-apply .job-card .apply-container .apply-steps .education-step .container .resume-field .uploaded .uploaded-buttons .view-btn,
.job-apply .job-card .apply-container .apply-steps .education-step .education-group .resume-field .uploaded .uploaded-buttons .view-btn,
.job-apply .job-card .apply-container .apply-steps .education-step .work-group .resume-field .uploaded .uploaded-buttons .view-btn,
.job-apply .job-card .apply-container .apply-steps .work-step .container .resume-field .uploaded .uploaded-buttons .view-btn,
.job-apply .job-card .apply-container .apply-steps .work-step .education-group .resume-field .uploaded .uploaded-buttons .view-btn,
.job-apply .job-card .apply-container .apply-steps .work-step .work-group .resume-field .uploaded .uploaded-buttons .view-btn {
  min-width: 119px;
  padding: 8px 30px;
  color: #212121;
  background: rgba(0, 52, 187, 0.05);
}
.job-apply .job-card .apply-container .apply-steps .basic-step .container .remove-wrapper,
.job-apply .job-card .apply-container .apply-steps .basic-step .education-group .remove-wrapper,
.job-apply .job-card .apply-container .apply-steps .basic-step .work-group .remove-wrapper,
.job-apply .job-card .apply-container .apply-steps .education-step .container .remove-wrapper,
.job-apply .job-card .apply-container .apply-steps .education-step .education-group .remove-wrapper,
.job-apply .job-card .apply-container .apply-steps .education-step .work-group .remove-wrapper,
.job-apply .job-card .apply-container .apply-steps .work-step .container .remove-wrapper,
.job-apply .job-card .apply-container .apply-steps .work-step .education-group .remove-wrapper,
.job-apply .job-card .apply-container .apply-steps .work-step .work-group .remove-wrapper {
  text-align: right;
}
.job-apply .job-card .apply-container .apply-steps .question-step {
  margin-top: 30px;
}
.job-apply .job-card .apply-container .apply-steps .question-step .question-wrapper {
  margin-bottom: 30px;
}
.job-apply .job-card .apply-container .apply-steps .question-step .question-wrapper .que {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
  margin-bottom: 2px;
  line-height: 171%;
}
.job-apply .job-card .apply-container .apply-steps .question-step .question-wrapper .choice-label .MuiTypography-root {
  font-size: 14px !important;
}
.job-apply .job-card .apply-container .apply-steps .work-step {
  margin-top: 0;
}
.job-apply .job-card .apply-container .apply-steps .row {
  display: flex;
  gap: 25px;
  margin-bottom: 25px;
}
.job-apply .job-card .apply-container .apply-steps .row > * {
  flex: 1;
}
.job-apply .job-card .apply-container .apply-steps .field {
  max-width: calc(50% - 12.5px);
}
.job-apply .job-card .apply-container .apply-steps .field label {
  font-size: 14px;
  color: #212121;
  line-height: 171%;
}
.job-apply .job-card .apply-container .apply-steps .field .mobile-error {
  font-size: 12px;
  color: #ba0000;
}
.job-apply .job-card .apply-container .apply-steps .heading {
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 30px;
}
.job-apply .job-card .apply-container .apply-steps .add-btn {
  margin-top: -10px;
}
.job-apply .job-card .apply-container .apply-steps .work-add-btn {
  margin-top: 20px;
}
.job-apply .preview h1 {
  font-size: 25px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 20px;
}
.job-apply .preview .cards {
  border: 1px solid rgba(33, 33, 33, 0.2);
  border-radius: 8px;
  padding: 30px;
  margin-bottom: 20px;
}
.job-apply .preview .cards .score {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
  margin-bottom: 5px;
}
.job-apply .preview .cards .basic {
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
  margin-bottom: 20px;
}
.job-apply .preview .cards .basic .basic-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.job-apply .preview .cards .basic .basic-top h3 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
}
.job-apply .preview .cards .basic .row {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 25px;
}
.job-apply .preview .cards .basic .row .left {
  width: 50%;
}
.job-apply .preview .cards .basic .row .left .label {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
  margin-bottom: 5px;
}
.job-apply .preview .cards .basic .row .left .value {
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 10px 15px;
  border-radius: 8px;
  height: 48px;
}
.job-apply .preview .cards .basic .row .left .value p {
  font-size: 16px;
  font-weight: 500;
  color: #212121;
}
.job-apply .preview .cards .basic .row .left .pdf-name {
  font-size: 16px;
  font-weight: 500;
  color: #212121;
}
.job-apply .preview .cards .basic .row .full-width {
  width: 100%;
}
.job-apply .preview .cards .basic .row .one-third {
  width: 33%;
}
.job-apply .preview .cards .education {
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
  margin-bottom: 20px;
}
.job-apply .preview .cards .education .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.job-apply .preview .cards .education .top h3 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
}
.job-apply .preview .cards .education .row {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 25px;
}
.job-apply .preview .cards .education .row .left {
  width: 33%;
}
.job-apply .preview .cards .education .row .left .label {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
  margin-bottom: 5px;
}
.job-apply .preview .cards .education .row .left .value {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 10px 15px;
  border-radius: 8px;
  height: 48px;
}
.job-apply .preview .cards .education .row .left .value p {
  font-size: 16px;
  font-weight: 500;
  color: #212121;
}
.job-apply .preview .cards .education .row .work-left {
  width: 50%;
}
.job-apply .preview .cards .education .check-box {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}
.job-apply .preview .cards .education .check-box p {
  font-size: 14px;
  font-weight: 500;
  color: #212121;
}
.job-apply .preview .cards .education .check-box .check {
  width: 18px;
  height: 18px;
  background: #0034bb;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.job-apply .preview .cards .question .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.job-apply .preview .cards .question .top h3 {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
}
.job-apply .preview .cards .question .row {
  margin-bottom: 15px;
}
.job-apply .preview .cards .question .row .que {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
  margin-bottom: 5px;
}
.job-apply .preview .cards .question .row .ans {
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 10px;
  border-radius: 8px;
}
.job-apply .preview .cards .question .row .ans p {
  font-size: 16px;
  font-weight: 500;
  color: #212121;
}
.job-apply .preview .cards .question .radio .que {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
  margin-bottom: 10px;
}
.job-apply .preview .cards .question .radio .check-box {
  display: flex;
  align-items: center;
  gap: 10px;
}
.job-apply .preview .cards .question .radio .check-box p {
  font-size: 14px;
  font-weight: 500;
  color: #212121;
}
.job-apply .preview .cards .question .radio .check-box .check {
  width: 18px;
  height: 18px;
  background: #0034bb;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.job-apply .preview .cards .question .radio .ans {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}
.job-apply .preview .cards .question .radio .ans .rad {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: #0034bb;
  display: flex;
  align-items: center;
  justify-content: center;
}
.job-apply .preview .cards .question .radio .ans .rad .iner {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: white;
}
.job-apply .preview .cards .question .radio .ans .empty {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 1px solid rgba(33, 33, 33, 0.2);
}
.job-apply .preview .cards .question .radio .ans p {
  font-size: 14px;
  font-weight: 400;
  color: #212121;
}
.job-apply .btn-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 70px;
}
.job-apply .btn-nav .back-btn {
  border-radius: 7px;
  padding: 10px 70px;
  box-shadow: none;
  border-color: rgba(33, 33, 33, 0.2);
}
.job-apply .btn-nav .next-btn {
  border-radius: 7px;
  padding: 10px 70px;
  box-shadow: none;
}

.mobile-input {
  height: auto;
  border-radius: 8px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding-right: 2px;
}
.mobile-input:hover, .mobile-input:focus, .mobile-input:focus-within {
  border-color: rgba(0, 52, 187, 0.7);
}
.mobile-input input {
  font-size: 16px;
  font-weight: 500;
  font-family: Poppins;
  color: #212121;
}
.mobile-input .form-control {
  width: 91%;
  border-radius: 8px;
  margin-left: 26px;
  border: unset;
  border-left: none;
  height: 43px;
}
.mobile-input .flag-dropdown {
  border-radius: 8px 0 0 8px;
  border-right: none;
  border: unset;
  background: rgba(255, 255, 255, 0.8);
}
.mobile-input .flag-dropdown .selected-flag {
  padding: 0 0 0 19px;
  background-color: unset;
}
.mobile-input .flag-dropdown .selected-flag .flag .arrow {
  border-top: unset;
  border-left: unset;
  border-right: unset;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M4 6L8 10L12 6" stroke="%23212121" stroke-opacity="0.8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  width: 16px;
  height: 16px;
  background-position: center;
  background-repeat: no-repeat;
  top: unset;
  left: 22px;
}

.enter-jobs-domain {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
}
.enter-jobs-domain .header {
  display: none;
}
.enter-jobs-domain .page {
  position: relative;
  overflow: hidden;
  border-radius: 13.347px;
  padding: 20px;
  margin: 20px;
  width: 100%;
  max-width: 810px;
  height: 100%;
  max-height: 487px;
  background: #fff;
  box-shadow: 0px 1.112px 2.224px 0px rgba(28, 28, 28, 0.24), 0px 0px 0px 1.112px rgba(28, 28, 28, 0.08);
  z-index: 0;
}
.enter-jobs-domain .page .bg_circle-wrapper {
  display: flex;
  flex-wrap: wrap;
  row-gap: 50px;
  position: absolute;
  z-index: -1;
  opacity: 0.17;
  -moz-opacity: 0.07;
}
.enter-jobs-domain .page .bg_circle-wrapper > .circle {
  border-radius: 325px;
  flex: 1 1 30%;
  overflow: visible;
}
.enter-jobs-domain .page .bg_circle-wrapper .circle-one {
  filter: blur(50px);
  position: absolute;
  width: 350px;
  height: 500px;
  left: -10rem;
  top: 10px;
  border-radius: 100%;
  background: #bde6fc;
}
.enter-jobs-domain .page .bg_circle-wrapper .circle-two {
  filter: blur(120px);
  position: absolute;
  right: 0.75rem;
  left: 29.75rem;
  top: 20rem;
  width: 300px;
  height: 325px;
  background: #7f00bb;
}
.enter-jobs-domain .page .bg_circle-wrapper .circle-three {
  filter: blur(180px);
  position: absolute;
  top: 17rem;
  left: 0rem;
  width: 220px;
  height: 225px;
  background: #7f00bb;
}
.enter-jobs-domain .page .page-content {
  width: 100%;
  height: 100%;
}
.enter-jobs-domain .page .page-content .background {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #212121;
}
.enter-jobs-domain .page .page-content .background .navbar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.enter-jobs-domain .page .page-content .background .navbar .logo {
  margin-left: 10px;
  height: 25px;
  width: 117px;
  cursor: pointer;
}
.enter-jobs-domain .page .page-content .background .navbar .logo img {
  max-width: 100%;
  max-height: 100%;
}
.enter-jobs-domain .page .page-content .background .navbar .home {
  display: inline-flex;
  padding: 4px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  color: #212121;
}
.enter-jobs-domain .page .page-content .background .main-group {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  max-height: 400px;
  height: 100%;
  margin: 10% auto;
}
.enter-jobs-domain .page .page-content .background .main-group .logo-jobs {
  display: flex;
  align-items: center;
}
.enter-jobs-domain .page .page-content .background .main-group .logo-jobs .logo {
  cursor: pointer;
  padding-right: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-right: 2px solid rgba(33, 33, 33, 0.2);
}
.enter-jobs-domain .page .page-content .background .main-group .logo-jobs .logo img {
  height: 35px;
  width: 160px;
  max-width: 100%;
  max-height: 100%;
}
.enter-jobs-domain .page .page-content .background .main-group .logo-jobs .jobs {
  margin-left: 25px;
  font-size: 25px;
  font-weight: 500;
  line-height: 37.832px;
  color: #212121;
}
.enter-jobs-domain .page .page-content .background .main-group .text-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin: auto;
  text-align: center;
}
.enter-jobs-domain .page .page-content .background .main-group .text-group .link {
  display: flex;
  align-items: center;
  gap: 4px;
  text-align: center;
}
.enter-jobs-domain .page .page-content .background .main-group .text-group .link .link-text {
  opacity: 40%;
}
.enter-jobs-domain .page .page-content .background .main-group .next-button {
  display: flex;
  justify-content: center;
  height: 40px;
}
.enter-jobs-domain .page .page-content .background .main-group .next-button .btn-contained {
  width: 250px;
  border-radius: 111px !important;
}

@media only screen and (max-width: 767px) {
  .enter-jobs-domain {
    justify-content: start;
    align-items: start;
  }
  .enter-jobs-domain .header {
    display: block;
  }
  .enter-jobs-domain .page {
    display: contents;
    padding: 20px;
    margin: 20px;
  }
  .enter-jobs-domain .page .bg_circle-wrapper {
    display: none;
  }
  .enter-jobs-domain .page .page-content {
    display: contents;
    width: 100%;
    height: 100%;
    margin: 20px;
    padding: 20px;
  }
  .enter-jobs-domain .page .page-content .background {
    margin-top: 30%;
    padding: 20px;
    margin: 20px;
    margin-top: 30%;
    max-height: 425px;
    border-radius: 8px;
    border: 1px solid rgba(33, 33, 33, 0.1);
    background: #fff;
    box-shadow: 0px 12px 40px 0px rgba(33, 33, 33, 0.05);
  }
  .enter-jobs-domain .page .page-content .background .navbar {
    display: none;
  }
  .enter-jobs-domain .page .page-content .background .main-group .logo-jobs .logo {
    padding-right: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .enter-jobs-domain .page .page-content .background .main-group .logo-jobs .logo img {
    height: 30px;
    width: 136px;
    max-width: 100%;
    max-height: 100%;
  }
  .enter-jobs-domain .page .page-content .background .main-group .logo-jobs .jobs {
    margin-left: 20px;
    font-size: 21px;
  }
  .enter-jobs-domain .page .page-content .background .main-group .text-group .main-text {
    font-size: 18px;
    font-weight: 500;
  }
  .enter-jobs-domain .page .page-content .background .main-group .text-group .link {
    flex-direction: column;
    align-items: start;
    width: 100%;
  }
  .enter-jobs-domain .page .page-content .background .main-group .text-group .link .co-name-input {
    width: 100%;
    position: relative;
  }
  .enter-jobs-domain .page .page-content .background .main-group .text-group .link .co-name-input .error-mssg {
    display: flex;
    position: absolute;
    left: 0px;
    top: 44px;
    gap: 2px;
    align-items: center;
    width: 100%;
    text-align: left;
    font-size: 14px;
    color: #ba0000;
  }
  .enter-jobs-domain .page .page-content .background .main-group .next-button {
    width: 100%;
    height: 40px;
    display: flex;
  }
  .enter-jobs-domain .page .page-content .background .main-group .next-button .btn-contained {
    width: 100%;
    border-radius: 8px !important;
  }
}
.not-found {
  margin-top: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.not-found .main-group-404 {
  margin: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  font-weight: 500;
}
.not-found .main-group-404 .doesnt-exist {
  font-size: 7px;
  padding: 3.5px;
  text-align: center;
  color: white;
  background-color: #1d2445;
  width: 100px;
  position: absolute;
  top: 114px;
  right: 183px;
  z-index: 10;
}
.not-found .main-group-404 .gif {
  max-width: 620px;
  max-height: 465px;
  width: 100%;
  height: 100%;
}
.not-found .main-group-404 .redirect {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 28.45px;
  bottom: 81px;
}
.not-found .main-group-404 .redirect .contained {
  border: none;
  outline: none;
  border-radius: 99px;
  box-shadow: 0px 1px 2px 0px rgba(69, 69, 69, 0.24), 0px 0px 0px 1px rgba(71, 71, 71, 0.08);
  padding: 25px;
  padding-bottom: 13px;
  cursor: pointer;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  width: 212px;
}
.not-found .main-group-404 .redirect .contained span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  transition: transform 0.6s;
  transform-origin: 50% 100%;
}
.not-found .main-group-404 .redirect .contained span:last-child {
  top: 100%;
  transform: translateY(100%);
}
.not-found .main-group-404 .redirect .contained:hover span, .not-found .main-group-404 .redirect .contained:focus span {
  transform: translateY(-100%);
}
@media only screen and (max-width: 767px) {
  .not-found .main-group-404 .doesnt-exist {
    font-size: 3.5px;
    width: 48.5px;
    top: 56px;
    right: 91px;
    padding: 1.7px;
  }
  .not-found .main-group-404 .gif {
    max-width: 306px;
    max-height: 230px;
  }
  .not-found .main-group-404 .redirect {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 11px;
    bottom: 35px;
  }
  .not-found .main-group-404 .redirect .contained {
    border: none;
    outline: none;
    border-radius: 99px;
    box-shadow: 0px 1px 2px 0px rgba(69, 69, 69, 0.24), 0px 0px 0px 1px rgba(71, 71, 71, 0.08);
    padding: 12px;
    padding-bottom: 13px;
    cursor: pointer;
    font-weight: 500;
    font-size: 12px;
    position: relative;
    overflow: hidden;
    width: 146px;
  }
  .not-found .main-group-404 .redirect .contained span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    transition: transform 0.6s;
    transform-origin: 50% 100%;
  }
  .not-found .main-group-404 .redirect .contained span:last-child {
    top: 100%;
    transform: translateY(100%);
  }
  .not-found .main-group-404 .redirect .contained:hover span, .not-found .main-group-404 .redirect .contained:focus span {
    transform: translateY(-100%);
  }
}